<template>
    <div class="content">
        <img src="@/assets/图标.jpg" alt="">
        <div class="content-title">迷你英语单词猫</div>
        <div class="content-tip">
            迷你英语单词猫 MINIMIAO BOT 1.0 是上海预醒网络科技有限公司旗下边缘骇客编程实验室制作的英语单词综合机器人，结合数学派迷你英语单词猫网站进行拓展服务，给初高中或者大学的同学们提供全新的英语学习新氛围。边缘骇客编程实验室在接下来的时间继续给同学们研制新的学习产品，对于任何疑问都可以在反馈空间和官方面对面进行沟通，希望同学们能保持热爱的激情在迷你英语单词猫去学习背诵英语单词，你我皆是黑马骐骥而敬庆前缘吧！
        </div>
        <div class="content-button">
            <button @click="$router.replace('/champion')">在线单词比赛</button>
            <button @click="$router.replace('/exercise')">在线智能答题</button>
            <button @click="$router.replace('/lexicon')">在线智能刷词</button>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    margin-right: 20px;
    padding: 20px 0;
    padding-bottom: 0;
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    overflow: hidden;
    height: 600px;
    box-sizing: border-box;
}

.content img {
    width: 150px;
    height: 150px;
    border-radius: 20px;
}

.content-title {
    font-size: 40px;
    margin-top: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(45deg, #175D88, #45B3F9, #337fae);
    display: flex;
    align-items: center;
    color: transparent;
    -webkit-background-clip: text;
}

.content-tip {
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: #a7b0c7;
    padding: 0 20px;
    text-align: justify;
    margin-bottom: auto;
    line-height: 30px;
    height: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}

.content-button {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    padding: 20px 10px;
    background-color: white;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

button {
    flex: 1;
    color: #2689B7;
    margin: 0 10px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    cursor: pointer;
    background-color: transparent;
    font-size: 15px;
    border: 2px solid #2689B7;
}

button:hover {
    color: white;
    background-color: #2689B7;
}
</style>