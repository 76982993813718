<template>
    <div class="phone-contain">
        <audio ref="english"></audio>
        <div class="phone-contain-login" v-if="['UNCONNECTED', 'DISCONNECTED'].includes(state)">
            <div class="header">
                <img class="header-logo" src="@/assets/图标.jpg" alt="">
                <div class="header-title">迷你英语单词猫</div>
                <a class="header-button" href="https://www.mslmsxp.com" target="_blank">数学派</a>
            </div>

            <div class="license-box-form">
                <div class="license-box-content">英语猫单词冠军赛</div>
                <img v-if="uid" class="license-box-image" :src="userNickAvatarFormat(uid)">
                <img v-else class="license-box-image" src="@/assets/默认头像.png">
                <input type="text" v-model="uid" placeholder="请填写您的 QQ 账号" :disabled="state == 'CONNECTED'">
                <div v-if="state == 'UNCONNECTED'" class="license-box-button" @click="startConnectChampionSocket">
                    开始单词答题全国排位赛</div>
                <div v-if="state == 'DISCONNECTED'" class="license-box-timer"><span
                        style="font-family: monospace; font-weight: bolder; font-size: 20px;">{{ formatSecond(leftTime)
                        }}</span></div>
                <div v-if="state == 'CONNECTED'" class="license-box-timer"><span
                        style="font-family: monospace; font-weight: bolder; font-size: 20px;">{{ formatSecond(leftTime)
                        }}</span></div>
            </div>
            <div class="online-user-box">
                <div class="online-user" v-for="(user, index) in onlineUserList" :key="index">
                    <img class="online-user-image" :src="userNickAvatarFormat(user.uid)" alt="" />
                    <div class="online-user-data">{{ user.point }}</div>
                </div>
                <div class="online-user" v-for="index in (10 - onlineUserList.length)" :key="10 - index">
                    <img class="online-user-image" src="@/assets/默认头像.png" alt="" />
                    <div class="online-user-data">0</div>
                </div>
            </div>
        </div>
        <div class="phone-contain-champion" v-else>
            <div class="online-user-box">
                <div class="online-user" v-for="(user, index) in onlineUserList" :key="index">
                    <img class="online-user-image" :src="userNickAvatarFormat(user.uid)" alt="" />
                    <div class="online-user-data">{{ user.point }}</div>
                </div>
                <div class="online-user" v-for="index in (10 - onlineUserList.length)" :key="10 - index">
                    <img class="online-user-image" src="@/assets/默认头像.png" alt="" />
                    <div class="online-user-data">0</div>
                </div>
            </div>
            
            <div class="chat-content-box" id="chat-box-content">
                <div v-for="(msg, index) in messageList" :key="index">
                    <div v-if="msg.mode == 'message' && msg.data.uid != uid">
                        <div class="message-time">{{ msg.time }}</div>
                        <div class="message">
                            <img class="message-image" :src="userNickAvatarFormat(msg.data.uid)" alt="" />
                            <div class="message-media-text">
                                {{ msg.data.text }}
                            </div>
                        </div>
                    </div>

                    <div v-if="msg.mode == 'message' && msg.data.uid == uid">
                        <div class="message-time">{{ msg.time }}</div>
                        <div class="message client">
                            <img class="message-image" :src="userNickAvatarFormat(msg.data.uid)" alt="" />
                            <div class="message-media-text">
                                {{ msg.data.text }}
                            </div>
                        </div>
                    </div>

                    <div v-if="msg.mode == 'problem'">
                        <div class="message-time">{{ msg.time }}</div>
                        <div class="message">
                            <img class="message-image"
                                src="http://192.168.31.196:8080/img/%E9%BB%98%E8%AE%A4%E5%A4%B4%E5%83%8F.89fdab0f.png"
                                alt="" />
                            <div class="message-media-problem">
                                <div class="problem-content">
                                    <div class="problem-content-data">题目内容：{{ msg.data.problem }}</div>
                                </div>
                                <div class="problem-option">
                                    <div class="problem-option-item" v-for="(option, i) in msg.data.option" :key="i">
                                        <div class="problem-option-item-tip">
                                            <div class="problem-option-item-tip-value">{{ ['A', 'B', 'C', 'D'][i] }}</div>
                                        </div>
                                        <div class="problem-option-item-content">
                                            {{ option }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="chat-operation-box">
                <button class="chat-operation-button">+</button>
                <input class="chat-operation-input" v-model="response" placeholder="填写消息内容" />
                <button class="chat-operation-button" @click="sendChatMessage">评论消息</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import Tool from "@/apis/tool"
import io from 'socket.io-client';
import Api from "@/apis";
import moment from "moment";

export default {
    data() {
        return {
            socket: null,
            timer: null,
            uid: "",
            token: "",
            point: 0,
            state: "UNCONNECTED",
            onlineUserList: [],
            sortUserList: [],
            currentProblem: { serial: "", problem: "English", option: ["", "", "", ""], answer: "" },
            problemList: [],
            leftTime: null,
            answerLeftTime: null,
            answerState: "BLANK",
            optionIndex: null,
            rightBox: "CHATBOX",
            messageList: [],
            response: ""
        };
    },
    methods: {
        formatSecond(seconds) { return Tool.formatSecond(seconds) },
        userNickAvatarFormat(uid) { return Tool.userNickAvatarFormat(uid) },
        formatDoubleIndex(value) {
            return (value + '').padStart(2, '0')
        },
        soundEnglish(word) {
            this.$refs.english.src = `https://dict.youdao.com/dictvoice?audio=${word}`
            this.$refs.english.play()
        },
        answerProblem(index, answer) {
            if (this.optionIndex == null) {
                this.optionIndex = index
                this.socket.emit("champion-answer", { code: 200, data: { uid: this.uid, token: this.token, answer: answer } })
            }
        },
        ComputeAnswerTime() {
            if (this.answerTimer) { clearInterval(this.answerTimer); this.answerTimer = null; }
            this.answerLeftTime = 5
            this.answerTimer = setInterval(() => {
                if (this.answerLeftTime > 0) { this.answerLeftTime = this.answerLeftTime - 0.01 } else {
                    clearInterval(this.answerTimer);
                    this.answerTimer = null;
                    if (!this.socket) { this.computeBeginTime() }
                }
            }, 10);
        },
        computeBeginTime() {
            if (['BEGINNING', "RESULT"].includes(this.state)) { return }
            Api.championTime().then(res => {
                if (res.data.code == 200) {
                    if (res.data.data.state) {
                        this.state = "DISCONNECTED"
                        this.leftTime = res.data.data.time
                        this.startBeginTimer()
                    } else {
                        if (this.state == "CONNECTED") {
                            this.leftTime = res.data.data.time
                            this.startBeginTimer()
                        } else {
                            this.state = "UNCONNECTED"
                            this.leftTime = res.data.data.time
                            this.startBeginTimer()
                        }
                    }
                }
            })
        },
        startBeginTimer() {
            if (this.timer) { clearInterval(this.timer); this.timer = null; }
            this.timer = setInterval(() => {
                if (this.leftTime > 0) { this.leftTime = this.leftTime - 0.01 } else {
                    clearInterval(this.timer);
                    this.timer = null;
                    if (!this.socket) { this.computeBeginTime() }
                }
            }, 10);
        },
        sendChatMessage() {
            if (this.response != "") {
                this.socket.emit("champion-chat", { code: 200, data: { uid: this.uid, token: this.token, text: this.response } })
                this.response = ""
            }
        },
        scrollBottom() {
            const chatContainer = document.getElementById('chat-box-content');
            chatContainer.scrollTop = chatContainer.scrollHeight;
        },
        startConnectChampionSocket() {
            if (!this.uid) { return }
            if (this.socket) { this.socket.close(); this.socket = null; }

            this.token = Tool.generateRandomString(16)

            this.socket = io.connect(`${this.$wsDomain}/champion?uid=${this.uid}&token=${this.token}`, {
                timeout: 1000, reconnectionDelayMax: 1000, reconnectionDelay: 5000
            })

            this.socket.on('connect', () => {
                this.state = "CONNECTED"
                this.socket.emit("champion-online", { code: 200 })
            })

            this.socket.on("champion-state", msg => {
                if (msg.code == 200) {
                    if (msg.data == true) {
                        this.state = "BEGINNING"
                    } else {
                        this.computeBeginTime()
                    }
                }
            })

            this.socket.on("champion-online", msg => {
                if (msg.code == 200) {
                    if (this.state != "RESULT") {
                        this.onlineUserList = msg.data
                    }
                    if (this.state == "BEGINNING") {
                        this.onlineUserList.forEach(user => {
                            if (user.uid == this.uid) {
                                this.answerState = user.state
                                this.point = user.point
                                if (this.answerState == "ERROR") {
                                    if (this.optionIndex != null) {
                                        let optionDivList = document.getElementsByClassName("test-problem-box-option-tip")
                                        optionDivList[this.optionIndex].style.backgroundColor = "#F88067"
                                        this.problemList[this.currentProblem.serial - 1].state = "ERROR"
                                        this.problemList = JSON.parse(JSON.stringify(this.problemList))
                                    }
                                }
                                if (this.answerState == "RIGHT") {
                                    if (this.optionIndex != null) {
                                        let optionDivList = document.getElementsByClassName("test-problem-box-option-tip")
                                        optionDivList[this.optionIndex].style.backgroundColor = "#0DBC79"
                                        this.problemList[this.currentProblem.serial - 1].state = "RIGHT"
                                        this.problemList = JSON.parse(JSON.stringify(this.problemList))
                                    }
                                }
                            }
                        })
                    }
                }
            })

            this.socket.on("champion-chat", msg => {
                console.log(msg)
                if (msg.code == 200) {
                    this.messageList.push({
                        mode: 'message',
                        time: moment().format("HH:mm:ss"),
                        data: msg.data
                    })
                    setTimeout(() => { this.scrollBottom() })
                }
            })

            this.socket.on("champion-problem", msg => {
                if (msg.code == 200) {
                    this.currentProblem = msg.data
                    this.soundEnglish(this.currentProblem.problem)

                    this.currentProblem['state'] = "BLANK"
                    this.problemList.push(this.currentProblem)
                    this.messageList.push({
                        mode: "problem",
                        time: moment().format("HH:mm:ss"),
                        data: this.currentProblem
                    })
                    this.ComputeAnswerTime()
                    setTimeout(() => { this.scrollBottom() })
                }
            })

            this.socket.on("champion-result", msg => {
                if (msg.code == 200) {
                    this.state = "RESULT"
                    this.sortUserList = msg.data.users
                    this.sortUserList.sort((a, b) => b.point - a.point);
                    if (msg.data.problems.length == this.problemList.length) {
                        this.problemList.forEach((problem, index) => {
                            problem['answer'] = msg.data.problems[index].answer
                        })
                    }
                    this.socket.close()
                }
            })
        }
    },
    mounted() {
        this.computeBeginTime()
        document.addEventListener('visibilitychange', this.computeBeginTime);
    },
    beforeDestroy() {
        if (this.socket) { this.socket.close() }
        if (this.timer) { clearInterval(this.timer) }
        if (this.answerTimer) { clearInterval(this.answerTimer) }
    }
};
</script>
<style scoped>
.phone-contain {
    background-color: #F1F6FD;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    min-width: 360px;
    max-width: 768px;
    margin: 0 auto;
    box-sizing: border-box;
    background-size: cover;
    background-image: url(@/assets/登录炫彩模糊背景.png);
}

.phone-contain-login {
    width: 100%;
    height: 100%;
}

.phone-contain-champion {
    width: 100%;
    height: 100%;
}

.header {
    height: 60px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    transition: all 0.5s;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
}

.header:hover {
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    background-color: rgba(255, 255, 255, 0.5);
}

.header-logo {
    width: 40px;
    height: 40px;
    display: flex;
    cursor: pointer;
    border-radius: 5px;
}

.header-title {
    margin-left: 10px;
    margin-right: auto;
}

.header-button {
    margin-left: 10px;
    padding: 0 15px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: 2px solid #0079BA;
    box-sizing: border-box;
    color: #0079BA;
    cursor: pointer;
    text-decoration: none;
}

.header-button:hover {
    background-color: #0079BA;
    color: white;
}

.license-box-form {
    background-color: #FFFFFF99;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    padding-top: 20px;
    box-sizing: border-box;
    margin: 10px;
}

.license-box-image {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
}

.license-box-content {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 20px;
    color: #0079BA;
}

.license-box-form input {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0 10px;
    box-sizing: border-box;
    font-family: "mathspie";
    border: 2px solid #0079BA;
    color: #0079BA;
    outline: none;
}

.license-box-button {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0079BA;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    color: #FFFFFF;
    cursor: pointer;
    margin-top: 10px;
}

.license-box-timer {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #A2B7C3;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    color: #FFFFFF;
    cursor: not-allowed;
    margin-top: 10px;
}

.phone-contain-champion * {
    font-size: 12px;
}

.chat-content-box {
    height: calc(100% - 165px);
    box-sizing: border-box;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 20px 0;
    text-align: center;
}

.online-user-box {
    box-sizing: border-box;
    margin-top: auto;
    display: flex;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    margin-bottom: 5px;
    padding: 10px 0;
    height: 80px;
    padding-right: 10px;
    background-color: #FFFFFF55;
    overflow-x: scroll;
}

.online-user {
    background-color: #FFFFFF;
    padding: 5px;
    margin-left: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
}

.online-user-image {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
}

.online-user-data {
    text-align: center;
}

.chat-operation-box {
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    padding: 10px 5px;
    padding-bottom: 40px;
    display: flex;
}

.chat-operation-input {
    border: 1px solid #000000;
    height: 30px;
    padding: 0 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #F1F6FD;
    box-sizing: border-box;
    flex: 1;
    margin: 0 5px;
}

.chat-operation-button {
    padding: 0 10px;
    height: 30px;
    background-color: #7A5CE8;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 5px;
    margin: 0 5px;
}

.message {
    display: flex;
    padding: 10px;
    width: auto;
    text-align: left;
}

.client {
    flex-direction: row-reverse;
}

.message-image {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    box-sizing: border-box;
}

.message-media-text {
    flex-shrink: 0;
    line-height: 20px;
    max-width: calc(100% - 80px);
    margin: 0 10px;
    box-sizing: border-box;
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    text-align: justify;
}

.message-media-problem {
    max-width: calc(100% - 80px);
    margin: 0 10px;
    border-radius: 5px;
    line-height: 20px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.5);
}


.problem-content {
    border-radius: 5px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 10px;
    background-color: #ffffff;
}

.problem-content-data {
    padding: 5px 10px;
    text-align: justify;
    border-radius: 5px;
}

.problem-option {
    margin-top: auto;
    padding: 0 10px;
    margin-bottom: 10px;
}

.problem-option-item {
    margin: 10px auto;
    margin-top: 0;
    border-radius: 5px;
    min-height: 40px;
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgb(99 99 99 / 0.1);
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-basis: 0;
    flex: 1;
}

.problem-option-item-tip {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.problem-option-item-tip-value {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D1DFEC;
    color: #ffffff;
    border-radius: 30px;
}

.problem-option-item-content {
    padding: 5px 10px;
    padding-left: 0;
    color: #7594AF;
    line-height: 20px;
    text-align: justify;
    flex: 1;
}
</style>